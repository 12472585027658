<template>
<div v-if="useHeaderBar && showAvatar" class="kai-avatar">
  <div class="lottie lottie-isdefault" aria-hidden="true" data-bm-renderer="svg"></div>
</div>
</template>

<script>

import './styles/Avatar.less'
import avatarPlaygroundJsonData from '../../debug/avatar_hd_playground.json'
import avatarIdleJsonData from '../../debug/avatar_hd_idle.json'
import AvatarAnimationMixin from './Mixin/AvatarAnimationMixin'
import ImageUtilityMixin from './Mixin/ImageUtilityMixin'
import Kai from '../../kai'

import avatarAgentStartJsonData from '../../debug/avatar_hd_agent_start.json'
import avatarQueueWaitingJsonData from '../../debug/avatar_hd_queue_waiting_loop.json'
import avatarAgentConnectingJsonData from '../../debug/avatar_hd_agent_connecting_loop.json'
import avatarAgentConnectedJsonData from '../../debug/avatar_hd_agent_connected_persistent.json'
import avatarAgentDisconnectedJsonData from '../../debug/avatar_hd_agent_disconnected.json'
import avatarAgentEndJsonData from '../../debug/avatar_hd_agent_end.json'

import Lottie from 'lottie-web'

export default {
  name: 'Avatar',
  created: function () {
    const _this = this
    // If Avatar are enable in the WV config, initialize with the following Avatar name
    if ($store.state.useAvatar) {
      this.$nextTick(() => {
        _this.loadAvatarAnimation(_this.initialAvatar)
      })
      this.createIdleTimer()
    }
  },
  data () {
    return {
      response: this.$slots,
      updateAnimationOnCompleted: false,
      initialAvatar: {
        state_name: 'splash2_bodymovin',
        loop: false
      },
      currentAvatar: {
        state_name: 'splash2_bodymovin',
        loop: false
      },
      previousAvatar: false,
      nextAvatar: false,
      extraAvatarAnimation: false,
      anim: undefined,
      runningAnimation: false,
      idleAnimationIndex: 0,
      idleAvatarTimerHolder: false
    }
  },
  mixins: [
    AvatarAnimationMixin,
    ImageUtilityMixin
  ],
  computed: { // Listen to see if store state change
    showAvatar () {
      const _this = this
      if ($store.state.useAvatar) {
        this.$nextTick(() => {
          _this.loadAvatarAnimation(_this.initialAvatar)
        })
      }
      return $store.state.useAvatar
    },
    useHeaderBar () {
      const _this = this
      if ($store.state.useAvatar) {
        this.$nextTick(() => {
          _this.loadAvatarAnimation(_this.initialAvatar)
        })
      }
      return $store.state.useHeaderBar
    },
    updateAvatar () {
      return this.$store.getters.getAvatar
    },
    resetTimer () {
      return this.$store.getters.isUserTyping || (this.$store.getters.getRenderingStatus === 'started')
    }
  },
  watch: {
    showAvatar () {
      return $store.state.useAvatar
    },
    updateAvatar () {
      this.createIdleTimer()
      const avatar = this.parseAvatarPayload(this.$store.getters.getAvatar)
      if (!this.runningAnimation) {
        this.previousAvatar = JSON.parse(JSON.stringify(this.currentAvatar))
        this.currentAvatar = avatar
        this.loadAvatarAnimation(avatar)
      } else {
        this.nextAvatar = avatar
        this.updateAnimationOnCompleted = true
      }
    },
    resetTimer () {
      this.createIdleTimer()
    }
  },
  methods: {
    parseAvatarPayload (messageContentAvatar) {
      const avatar = {
        ...messageContentAvatar.data ? messageContentAvatar.data : messageContentAvatar.payload ? messageContentAvatar.payload : {}
      }
      avatar.state_name = avatar.name ? avatar.name : avatar.state_name ? avatar.state_name : false
      avatar.loop = !!avatar.loop
      return JSON.parse(JSON.stringify(avatar))
    },
    async loadAvatarAnimation (avatar) {
      const _this = this
      this.createIdleTimer()

      if (avatar.state_name) {
        avatar.state_name = this.mapNameToFile(avatar.state_name)
        avatar = this.checkForExtraAnimations(avatar)
      }


      const persistent = (avatar.display_mode && avatar.display_mode.toUpperCase()) === 'PERSISTENT'
      if (persistent) {
        $store.dispatch('actionAvatarPersistentUpdate', persistent)
      }

      const title = (avatar.title && avatar.title.length > 0) ? avatar.title : ''
      if (title !== $store.getters.getAvatarTitle) {
        $store.dispatch('actionAvatarTitle', title)
      }

      // Object
      const avatarObj = {
        container: document.getElementsByClassName('lottie')[0], // the dom element that will contain the animation
        renderer: 'svg',
        loop: avatar.loop,
        autoplay: false
      }
      if ($store.getters.isInlineAvatarEnabled) {
        avatarObj.animationData = await Kai.API.getAvatarFile(avatar.state_name)
      } else {
        avatarObj.path = await Kai.API.getAvatarFile(avatar.state_name)
      }

      await this.prepareAssets(avatarObj)

      // Attach object
      this.anim = Lottie.loadAnimation(avatarObj)

      this.loadLogoImage(_this, this.anim)

      this.anim.addEventListener('complete', function () {
        _this.completeAnimation()
      })
      this.anim.addEventListener('loopComplete', function () {

        if (_this.updateAnimationOnCompleted) {
          // _this.anim.stop()
          _this.completeAnimation()
        }
      })
    },
    completeAnimation () {
      this.runningAnimation = false
      this.createIdleTimer()
      if (this.extraAvatarAnimation) {
        this.dispatchExtraAnimation()
      } else if (this.updateAnimationOnCompleted) {
        this.updateAnimationOnCompleted = false
        this.previousAvatar = JSON.parse(JSON.stringify(this.currentAvatar))
        this.currentAvatar = JSON.parse(JSON.stringify(this.nextAvatar))
        this.nextAvatar = false
        this.loadAvatarAnimation(this.currentAvatar)
      }
    },
    removePreviousAnimation () {
      const container = document.getElementsByClassName('lottie')[0]
      // remove the previous animation svg child (if any) only once the new animation start playing
      this.$nextTick(() => {
        while (container.childNodes !== undefined && container.childNodes.length > 1) {
          container.removeChild(container.childNodes[0])
        }
      })
    },
    createIdleTimer () {
      const _this = this
      if (this.idleAvatarTimerHolder) {
        clearTimeout(this.idleAvatarTimerHolder)
      }
      this.idleAvatarTimerHolder = setTimeout(function () {
        if (!$store.getters.isAvatarPersistent && !$store.getters.isLiveChatStarted) {
          _this.presentIdleTimer()
        }
        _this.createIdleTimer()
      }, 20000)
    },
    presentIdleTimer () {
      if (this.idleAnimationIndex === 0) {
        this.idleAnimationIndex++
        $store.dispatch('actionAvatarUpdate', JSON.parse(JSON.stringify(avatarPlaygroundJsonData.message_contents[0].payload)))
        // trigger the playground avatar again a few milliseconds later
        setTimeout(function () {
          // We use JSON.parse and stringify to create a new object and make sure the Vue mutation is getting triggered
          $store.dispatch('actionAvatarUpdate', JSON.parse(JSON.stringify(avatarPlaygroundJsonData.message_contents[0].payload)))
        }, 500)
      } else {
        this.idleAnimationIndex = 0
        $store.dispatch('actionAvatarUpdate', JSON.parse(JSON.stringify(avatarIdleJsonData.message_contents[0].payload)))
      }
    },
    mapNameToFile (name) {
      switch (name.toUpperCase()) {
        case 'DEFAULT_AVATAR_STATE': {
          return 'active_bodymovin'
        }
        case 'CHAT_WITH_ME_AVATAR_STATE': {
          return 'chat_icon_bodymovin'
        }
        case 'CHECKMARK_AVATAR_STATE': {
          return 'checkmark_bodymovin'
        }
        case 'EXTENDED_IDLE(ZZZ)_AVATAR_STATE': {
          return 'idle_bodymovin'
        }
        // case '': {
        //   return 'leaving_bodymovin'
        // }
        // case '': {
        //   return 'notification_bodymovin'
        // }
        case 'INTERIM_IDLE_AVATAR_STATE': {
          return 'playground_bodymovin'
        }
        case 'QUESTION_AVATAR_STATE': {
          return 'question_bodymovin'
        }
        case 'SORRY_AVATAR_STATE': {
          return 'sorry_bodymovin'
        }
        case 'THANK_YOU_AVATAR_STATE': {
          return 'thank_you_bodymovin'
        }
        case 'TRANSACTION_AVATAR_STATE': {
          return 'transaction_bodymovin'
        }
        case 'QUEUE_WAITING_AVATAR_STATE': {
          return 'waiting_bodymovin'
        }
        case 'AGENT_CONNECTING_AVATAR_STATE': {
          return 'agent_connecting_bodymovin'
        }
        case 'AGENT_CONNECTED_AVATAR_STATE': {
          return 'agent_connected_bodymovin'
        }
        case 'AGENT_DISCONNECTED_AVATAR_STATE': {
          return 'agent_disconnected_bodymovin'
        }
        default:
          return name
      }
    },
    checkForExtraAnimations (avatar) {
      // live chat animations sequences
      //  - agent_connecting_bodymovin: starting anim + persistent loop main anim + ending
      //  - waiting_bodymovin: starting anim + persistent loop main anim + ending
      //  - agent_connecting_bodymovin:  starting anim + persistent loop + ending
      //  - agent_connected_bodymovin: starting anim + persistent main anim
      //  - agent_disconnected_bodymovin: main anim + ending
      if (avatar.state_name === 'agent_end_bodymovin') {
        if ((this.previousAvatar.state_name === 'waiting_bodymovin' || this.previousAvatar.state_name === 'agent_connecting_bodymovin') && this.previousAvatar.state_name === this.nextAvatar.state_name) {
          // if previously playing waiting_bodymovin we don't want to play the agent_and and agent_start animation again

          this.updateAnimationOnCompleted = false
          this.currentAvatar = JSON.parse(JSON.stringify(this.nextAvatar))
          avatar = JSON.parse(JSON.stringify(this.currentAvatar))
          this.extraAvatarAnimation = this.parseAvatarPayload(JSON.parse(JSON.stringify(avatarAgentEndJsonData.message_contents[0])))
          this.extraAvatarAnimation.title = avatar.title
          this.nextAvatar = false
        }
      } else if (avatar.state_name === 'waiting_bodymovin') {
        // place the "queue waiting" animation in the queue so it can be played as soon as the "agent start" animation is done.
        this.extraAvatarAnimation = this.parseAvatarPayload(JSON.parse(JSON.stringify(avatarQueueWaitingJsonData.message_contents[0])))
        this.extraAvatarAnimation.title = avatar.title
        avatar.state_name = 'agent_start_bodymovin'
        avatar.loop = false
      } else if (avatar.state_name === 'waiting_loop_bodymovin') {
        // place the agent end animation in the queue so it can be played as soon as the "queue waiting" animation is done.
        this.extraAvatarAnimation = this.parseAvatarPayload(JSON.parse(JSON.stringify(avatarAgentEndJsonData.message_contents[0])))
        this.extraAvatarAnimation.title = avatar.title
        avatar.state_name = 'waiting_bodymovin'
      } else if (avatar.state_name === 'agent_connecting_bodymovin') {
        // place the agent connecting animation in the queue so it can be played as soon as the "agent start" animation is done.
        this.extraAvatarAnimation = this.parseAvatarPayload(JSON.parse(JSON.stringify(avatarAgentConnectingJsonData.message_contents[0])))
        this.extraAvatarAnimation.title = avatar.title
        avatar.state_name = 'agent_start_bodymovin'
        avatar.loop = false
      } else if (avatar.state_name === 'agent_connecting_loop_bodymovin') {
        // place the agent end animation in the queue so it can be played as soon as the "agent connecting" animation is done.
        this.extraAvatarAnimation = this.parseAvatarPayload(JSON.parse(JSON.stringify(avatarAgentEndJsonData.message_contents[0])))
        this.extraAvatarAnimation.title = avatar.title
        avatar.state_name = 'agent_connecting_bodymovin'
      } else if (avatar.state_name === 'agent_connected_bodymovin') {
        if (this.previousAvatar.state_name === 'agent_connected_bodymovin') {
          // if already playing agent_connected_bodymovin we don't want to play the agent_start animation again
          return avatar
        }
        // place the agent connected animation in the queue so it can be played as soon as the "agent start" animation is done.
        this.extraAvatarAnimation = this.parseAvatarPayload(JSON.parse(JSON.stringify(avatarAgentConnectedJsonData.message_contents[0])))
        this.extraAvatarAnimation.title = avatar.title
        avatar.state_name = 'agent_start_bodymovin'
        avatar.loop = false
      } else if (avatar.state_name === 'agent_connected_persistent_bodymovin') {
        avatar.state_name = 'agent_connected_bodymovin'
      } else if (avatar.state_name === 'agent_disconnected_bodymovin') {
        // place the agent end animation in the queue so it can be played as soon as the "agent disconnected" animation is done.
        this.extraAvatarAnimation = this.parseAvatarPayload(JSON.parse(JSON.stringify(avatarAgentEndJsonData.message_contents[0])))
        this.extraAvatarAnimation.title = avatar.title
      }
      return avatar
    },
    dispatchExtraAnimation () {
      if (this.extraAvatarAnimation) {
        // when animation is looping we reset the Idle Timer to not interrupt it.
        this.createIdleTimer()
        this.previousAvatar = JSON.parse(JSON.stringify(this.currentAvatar))
        this.currentAvatar = JSON.parse(JSON.stringify(this.extraAvatarAnimation))
        this.extraAvatarAnimation = false
        this.loadAvatarAnimation(this.currentAvatar)
      }
    }
  }
}
</script>
