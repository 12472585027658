module.exports = {
  data () {
    return {
      imageLoadSuccessHolder: false,
      imageLoadErrorHolder: false,
      isLogoCached: false,
      cachedLogoURL: false,
      logoPath: false
    }
  },
  methods: {
    loadLogoImage (context, anim) {
      const container = document.getElementsByClassName('lottie')[0]
      anim.addEventListener('DOMLoaded', () => {
        // replace in the animation the path to the Logo image used in the Avatar animation
        const imageElements = container.lastChild.getElementsByTagName('image')
        let imageReplaced = false
        for (let i = 0; i < imageElements.length; i++) {
          const element = imageElements[i]
          if (element.getAttribute('width') === '300px') {
            imageReplaced = true
            this.imageLoadSuccessHolder = () => context.onImageLoadSuccess(context, element)
            element.addEventListener('load', this.imageLoadSuccessHolder, true)
            if ($store.getters.isLiveAgentConnected) {
              this.$nextTick(() => {
                // element.setAttribute('href', this.getImagePath('Agent.svg')
                const template = document.createElement('template')
                template.innerHTML = this.getAgentSVGString()
                element.parentElement.replaceChildren(template.content)
                context.onImageLoadSuccess(context, element)
              })
            }
            if (!$store.getters.isInlineAvatarEnabled) {
              this.$nextTick(() => {
                element.setAttribute('href', this.logoPath)
              })
            }
            if ($store.getters.isInlineImagesEnabled || this.isLogoCached) {
              this.$nextTick(() => {
                this.imageLoadSuccessHolder()
              })
            }
            this.imageLoadErrorHolder = () => context.onImageLoadError(context, element)
            element.addEventListener('error', this.imageLoadErrorHolder, true)
          } else {
            imageReplaced = true
          }
        }
        if (!imageReplaced) {
          // run the animation with default logo
          anim.play()
          context.removePreviousAnimation()
        }
        context.runningAnimation = true
      }, true)
    },
    onImageLoadSuccess (context, element) {
      element.removeEventListener('load', this.imageLoadSuccessHolder, true)
      context.anim.play()
      if (context.removePreviousAnimation) {
        context.removePreviousAnimation()
      }
    },
    onImageLoadError (context, element) {
      // if an issue happened just try one more time to run the animation with backup logo
      element.removeEventListener('error', this.imageLoadErrorHolder, true)
      this.$nextTick(() => {
        element.setAttribute('href', $store.getters.getBotLogoBackupUrl)
        context.anim.play()
      })
    },
    async prepareAssets (avatarObj) {
      const asset = avatarObj.animationData ? avatarObj.animationData.assets.filter(asset => asset.id === 'image_0')[0] : {}
      if ($store.getters.isInlineImagesEnabled) {
        try {
          asset.p = require('@/assets/img/' + $store.getters.getBotLogoInlineUrl)
        } catch (error) {
          try {
            asset.p = $store.getters.getBotLogoInlineUrl
          } catch (error) {
            asset.p = $store.getters.getBotLogoUrl
          }
        }
      } else {
        asset.p = $store.getters.getBotLogoUrl
      }
      asset.e = true
      if (!this.isLogoCached || asset.p !== this.cachedLogoURL) {
        this.isLogoCached = await this.checkImageCached(asset.p)
        if (this.isLogoCached) {
          this.cachedLogoURL = asset.p
        }
      }
      if (!$store.getters.isInlineAvatarEnabled) {
        this.logoPath = asset.p
      }
    },
    async checkImageCached (url, waitTimeMs = 40) {
      const ac = new AbortController()
      const cachePromise = fetch(url, {
        signal: ac.signal
      })
        .then(() => true)
        .catch(() => false)
      setTimeout(() => ac.abort(), waitTimeMs)
      return cachePromise
    }
  }
}
