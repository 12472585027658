<template>
<div v-if="this.webviewIsReady">
    <div class="theme-app" :class="{show:showThemeChanger}">
        <div id="webview-container" class="webview-container" role="main" :aria-roledescription="screenReaderTranslations.virtualAssistantDesignation + ' ' + screenReaderTranslations.chatArea" :class="{webview_container_widget:useWidgetMode, align_left:alignWidgetLeft, webview_rtl_support: enableRTL, live_chat_open:isLiveChatStarted}" v-show="this.showWidget">
            <SplashScreen v-if="showSplashScreen && showWidget"></SplashScreen>
            <HeaderBar v-if="!showSplashScreen && showWidget"></HeaderBar>
            <Container v-show="!showSplashScreen"></Container>
            <QuickRepliesBar></QuickRepliesBar>
            <BottomBar v-if="!showSplashScreen" id="bottombar"></BottomBar>
            <LiveChatWidget :openUp="true"></LiveChatWidget>
            <ShortcutPanel :class="{shortcut_widget:useWidgetMode}"></ShortcutPanel>
            <InlinePopup :class="{inline_popup_widget:useWidgetMode}"></InlinePopup>
        </div>
      <PoweredBySnippet v-if="useWidgetMode && showWidget" class="powered_by_kasisto" :class="{live_chat_open:isLiveChatStarted}"></PoweredBySnippet>
      <LiveChatWidget v-if="useWidgetMode && showWidget" :openUp="false"></LiveChatWidget>
        <div navDir="horizontal">
          <div class="webview_widget_launcher" role="button" :aria-label="screenReaderTranslations.open + ' ' + screenReaderTranslations.virtualAssistantDesignation" tabindex="0" navLvl="1" navDir="horizontal" ref="keyboardWrapper" v-show="!this.showWidget" v-on:click="this.toggleWidgetDisplay">
              <img aria-hidden="true" class="webview_widget_icon" ondragstart="return false;">
          </div>
        </div>
    </div>
    <transition name="theme-editor-slide-fade" v-on:enter="moveWidget" v-on:after-leave="moveWidget">
        <div v-if="showThemeChanger" class="theme-editor" :class="{show:showThemeChanger}">
            <ThemeChanger></ThemeChanger>
        </div>
    </transition>
</div>
</template>

<script>
import Container from './core/components/Container'
import BottomBar from './core/components/BottomBar'
import ThemeChanger from './core/components/ThemeChanger'
import HeaderBar from './core/components/HeaderBar'
import ShortcutPanel from './core/components/ShortcutPanel'
import InlinePopup from './core/components/InlinePopup'
import QuickRepliesBar from './core/components/QuickRepliesBar'
import LiveChatWidget from './core/components/LiveChatWidget'
import SplashScreen from './core/components/SplashScreen'
import PoweredBySnippet from './core/components/PoweredBySnippet'
import KeyboardNavigationMixin from './core/components/Mixin/KeyboardNavigationMixin'
import Cookies from 'js-cookie'

export default {
  name: 'App',
  components: {
    Container,
    BottomBar,
    ThemeChanger,
    HeaderBar,
    ShortcutPanel,
    QuickRepliesBar,
    SplashScreen,
    PoweredBySnippet,
    LiveChatWidget,
    InlinePopup
  },
  data () {
    return {
      showSplashScreen: false,
      alignWidgetLeft: false,
      screenReaderTranslations: $store.getters.getBotLanguages.translations.screenReader,
      imageLoadErrorHolder: false
    }
  },
  computed: {
    webviewIsReady () {
      return $store.state.webviewReady
    },
    widgetIconSrc () {
      if ($store.getters.isInlineImagesEnabled) {
        try {
          return require('@/assets/img/' + $store.getters.getBotWidgetIconInlineUrl)
        } catch (error) {
          return $store.getters.getBotWidgetIconInlineUrl
        }
      } else {
        return $store.state.botWidgetIconUrl
      }
    },
    useWidgetMode () {
      return $store.state.useWidgetMode
    },
    showThemeChanger () {
      return $store.state.showThemeChanger
    },
    showWidget () {
      const show = $store.state.showWebviewWidget || !this.useWidgetMode
      if (!show) {
        this.isStartupWidgetDisplayed()
      }
      return show
    },
    isSplashScreenEnabled () {
      return $store.getters.isSplashScreenEnabled
    },
    enableRTL () {
      return $store.state.enableRTL
    },
    isLiveChatStarted () {
      return this.$store.getters.isLiveChatStarted
    }
  },
  watch: {
    showWidget () {
      this.checkSplashScreenCookie()
    },
    isSplashScreenEnabled () {
      this.showSplashScreen = $store.getters.isSplashScreenEnabled
    },
    enableRTL () {
      this.applyFontSizeFactor()
    }
  },
  mounted () {
    this.checkSplashScreenCookie()
    this.applyFontSizeFactor()
    setTimeout(() => {
      const widgetLauncherIcon = document.getElementsByClassName('webview_widget_icon')[0]
      if (widgetLauncherIcon) {
        if (!$store.getters.isInlineImagesEnabled) {
          this.imageLoadErrorHolder = () => this.onImageLoadError(this, widgetLauncherIcon)
          widgetLauncherIcon.addEventListener('error', this.imageLoadErrorHolder)
        }
        widgetLauncherIcon.setAttribute('src', this.widgetIconSrc)
      }
    }, 500)
  },
  methods: {
    toggleWidgetDisplay: function () {
      $store.state.showWebviewWidget = !$store.state.showWebviewWidget
      Kai.API.sendUIEvent('webview_widget_opened', 'webview_widget_chat_icon', 'clicked', 'Webview Widget Opened')
      Cookies.set('k_webview_widget_opened', $store.state.showWebviewWidget)
      if ($store.getters.getKaiSessionId === '' && $store.getters.getStartSession === true) {
        kserver.CoreAPI.startSession()
      }
    },
    moveWidget () {
      this.alignWidgetLeft = this.showThemeChanger
    },
    onImageLoadError (context, element) {
      // if an issue happened just try one more time to run the animation with backup logo
      element.removeEventListener('error', this.imageLoadErrorHolder)
      this.$nextTick(() => {
        element.setAttribute('src', $store.getters.getBotWidgetIconBackupUrl)
      })
    },
    checkSplashScreenCookie () {
      if ($store.getters.isSplashScreenEnabled) {
        const skipSplashScreen = Cookies.get('k_splashscreen_opened')

        if (!skipSplashScreen) {
          this.showSplashScreen = true
        } else {
          $store.state.useSplashScreen = false
          this.showSplashScreen = false
        }
      } else {
        $store.state.useSplashScreen = false
        this.showSplashScreen = false
      }
    },
    applyFontSizeFactor () {
      if (document.getElementsByClassName('webview--app').length > 0) {
        const initialFactor = parseInt(getComputedStyle(document.getElementsByClassName('webview--app')[0]).getPropertyValue('--font-size-factor').trim())
        if ($store.state.enableRTL) {
          document.getElementsByClassName('webview--app')[0].style.setProperty('--font-size-factor', initialFactor * $store.state.RTLFontSizeFactor)
        } else {
          document.getElementsByClassName('webview--app')[0].style.setProperty('--font-size-factor', initialFactor)
        }
      }
    },
    isStartupWidgetDisplayed () {
      if ($store.state.webviewReady && this.useWidgetMode && !$store.state.showWebviewWidget) {
        // Reeach here the webview widget is not displayed and the webview_widget_launcher icon is displayed instead
        this.$nextTick(() => {
          try {
            Kai.API.sendUIEvent('webview_startup_widget_displayed', 'webview_widget_chat_icon', 'displayed', 'Webview Widget Closed')
          } catch (error) {
            console.error(error)
          }
        })
      }
    }
  },
  mixins: [
    KeyboardNavigationMixin
  ]
}
</script>

<style>

@font-face {
font-family: 'Lato';
src: local("Lato"), url('~@/assets/fonts/Lato/Lato-Regular.ttf') format('truetype');
}

body {
    margin: 0;
}

#webview-container, .webview--app .dateCalendar-module, .live-chat-widget {
    font-family: 'Lato', 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    box-shadow: 2px 2px 20px 0px rgb(0 0 0 / 15%);
}

.webview_container_widget{
  position: fixed;
  right: 30px;
  bottom: 60px;
  z-index: 100002;
  background: #fff;
  border-radius: 20px;
  height: calc(100% - var(--bottom-bar-height));
  width: 385px;
  overflow: hidden;
}
.webview_container_widget.align_left{
  left:30px;
}

.webview-container{
  height:  calc(100% - var(--bottom-bar-height));
}

@media only screen and (min-height: 750px) and (min-width: 435px) {
  .webview_container_widget {
    height: 680px;
  }

}

@media screen and (max-width: 435px) {
    .webview_container_widget {
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        border-radius: 0;
        height: 100%;
        width: 100%;
    }
    .webview_container_widget.align_left{
      left:0px;
    }
}

@media screen and (min-width: 435px) {
  .webview_container_widget.live_chat_open{
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    transition: var(--transition-live-chat-widget);
    transition-property: z-index;
    transition-delay: 1s;
    z-index: 100001;
  }
}

.webview_widget_launcher {
    z-index: 100001;
    position: absolute;
    right: 40px;
    bottom: 70px;
    cursor: pointer;
}

.webview_widget_launcher:focus{
  outline: none;
  box-shadow: 0 0 5px 3px var(--highlight-color);
  border-radius: 40px;
}

.webview_widget_launcher .webview_widget_icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    float: left;
    box-shadow: var(--shadow);
}

.theme-app {
    background-color: #F4F4F4;

    transition: all .5s ease;
}

.theme-app.show {
    right: 300px;
    transition: all .5s ease;
}

@media screen and (max-width: 480px) {
  .__themeChanger {
   display: none;
  }

  .debug-box img {
    width: 30%;
 }
}

.theme-editor {
    position: absolute;
    top: 0;
    width: 0;
    right: 0;
    bottom: 0;
}

.theme-editor.show {
    width: 300px;
}

.theme-editor-slide-fade-enter-active {
    transition: all .5s ease;
}

.theme-editor-slide-fade-leave-active {
    transition: all .5s ease;
}

.theme-editor-slide-fade-enter,
.theme-editor-slide-fade-leave-to {
    transform: translateX(300px);
}

.powered_by_kasisto{
    position: fixed;
    right: 155.5px;
    bottom: 33px;
    z-index: 100002;
}

.powered_by_kasisto.live_chat_open{
  z-index: 100000;
  transition: all 0.5s ease-in-out;
  transition-property: bottom, display;
  bottom:66px;
}
</style>
