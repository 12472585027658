<template lang="en">
<section id="header-bar">
<div class="webview-header-wrapper" :class="{small:!useAvatar}" navDir="horizontal">
  <div v-if="useWidgetMode" v-show="useHeaderBar" role="button" :aria-label="screenReaderTranslations.minimize + ' ' + screenReaderTranslations.virtualAssistantDesignation" tabindex="0" navLvl="1" navDir="horizontal" class="minimize-webview" :class="{align_right: alignIconRight}" v-on:click="this.onMinimizeClick" ref="keyboardWrapper"><span aria-hidden="true" class="c-icon icon-minimize-webview" :style="'background-image: url(' + this.minimizeIcon + ')'"></span></div>
  <div class="webview-header" v-show="useHeaderBar">
    <Avatar v-if="showAvatar"></Avatar>
    <div v-if="!useAvatar" class="webview-title">{{title}}</div>
  </div>
  <AvatarTitle></AvatarTitle>
  <div v-show="useHeaderBar" class="webview-header-bottom-padding"></div>
</div>
</section>
</template>

<script>

import Avatar from './Avatar'
import AvatarTitle from './AvatarTitle.vue'
import KeyboardNavigationMixin from './Mixin/KeyboardNavigationMixin'
import ImageUtilityMixin from './Mixin/ImageUtilityMixin'
import Cookies from 'js-cookie'
import './styles/HeaderBar.less'

export default {
  name: 'HeaderBar',
  data () {
    return {
      response: this.$slots,
      minimizeIcon: this.getImagePath('close.png'), // TODO: should be change with a minimize icon
      title: $store.getters.getBotLanguages.translations.header.title,
      screenReaderTranslations: $store.getters.getBotLanguages.translations.screenReader
    }
  },
  computed: {
    useHeaderBar () {
      return $store.state.useHeaderBar
    },
    useWidgetMode () {
      return $store.state.useWidgetMode
    },
    useAvatar: function () {
      return $store.state.useAvatar
    },
    showAvatar () {
      return !$store.getters.isSplashScreenEnabled
    },
    alignIconRight () {
      return $store.state.headerBarConfig && $store.state.headerBarConfig.minimizeButtonAlignRight
    }
  },
  watch: {
    useAvatar: function () {
      return $store.state.useAvatar
    },
    showAvatar () {
      return !$store.getters.isSplashScreenEnabled
    }
  },
  methods: {
    onMinimizeClick () {
      if ($store.state.enableMinimizeIntent) {
        kserver.CoreAPI.sendIntent('kcb_minimize')
      } else {
        Kai.API.minimizeWebviewWidget()
      }
    }
  },
  components: {
    Avatar,
    AvatarTitle
  },
  mixins: [
    KeyboardNavigationMixin,
    ImageUtilityMixin
  ]
}
</script>
