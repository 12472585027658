
/****************************************************/

/* Store Actions  */

/****************************************************/
const actionKaiSessionId = ({ commit }, payload) => {
  commit('mutateKaiSessionId', payload)
}

const actionDebugMode = ({ commit }, payload) => {
  commit('mutateDebugMode', payload)
}

const actionInputMessage = ({ commit }, payload) => {
  commit('mutateInputMessage', payload)
}

const actionSendMessage = ({ commit }, payload) => {
  commit('mutateSendMessage', payload)
}

const actionSendSelection = ({ commit }, payload) => {
  commit('mutateSendSelection', payload)
}

const actionResponseData = ({ commit }, payload) => {
  commit('mutateResponseData', payload)
}

const actionRenderingStatus = ({ commit }, payload) => {
  commit('mutateRenderingStatus', payload)
}

const actionTypingIndicator = ({ commit }, payload) => {
  commit('mutateTypingIndicator', payload)
}

const actionLastMessageReceievedEpoc = ({ commit }, payload) => {
  commit('mutateLastMessageReceievedEpoc', payload)
}

const actionCarouselCounter = ({ commit }, payload) => {
  commit('mutateCarouselCounter', payload)
}

const actionPullServiceStart = ({ commit }) => {
  commit('mutatePullServiceStatus', true)
}

const actionPullServiceStop = ({ commit }) => {
  commit('mutatePullServiceStatus', false)
}

const actionRequestHeaders = ({ commit }, payload) => {
  commit('mutateRequestHeaders', payload)
}

const actionUserTyping = ({ commit }, payload) => {
  commit('mutateUserTyping', payload)
}

const actionAvatarUpdate = ({ commit }, payload) => {
  commit('mutateAvatar', payload)
}

const actionAvatarPersistentUpdate = ({ commit }, payload) => {
  commit('mutateAvatarPersistent', payload)
}

const actionAvatarTitle = ({ commit }, payload) => {
  commit('mutateAvatarTitle', payload)
}

const actionShortcutPayload = ({ commit }, payload) => {
  commit('mutateShortcutPayload', payload)
}

const actionShortcutIcon = ({ commit }, icon) => {
  commit('mutateShortcutIcon', icon)
}

const actionShowInlinePopup = ({ commit }, isVisible) => {
  commit('mutateShowInlinePopup', isVisible)
}

const actionInlinePopupContent = ({ commit }, payload) => {
  commit('mutateInlinePopupContent', payload)
}

const actionBotLanguages = ({ commit }, payload) => {
  commit('mutateBotLanguages', payload)
}

const actionUseAvatar = ({ commit }, payload) => {
  commit('mutateUseAvatar', payload)
}

const actionUseHeaderBar = ({ commit }, payload) => {
  commit('mutateUseHeaderBar', payload)
}

const actionSlotsDefault = ({ commit }, payload) => {
  commit('mutateSlotsDefault', payload)
}

const actionSpliceSelectedItemsIndex= ({ commit }, payload) => {
  commit('mutateSpliceSelectedItemsIndex', payload)
}

const actionPushSelectedItemsIndex= ({ commit }, payload) => {
  commit('mutatePushSelectedItemsIndex', payload)
}

const actionEmptySelectedItemsIndex= ({ commit }) => {
  commit('mutateEmptySelectedItemsIndex')
}

/****************************************************/

/* Export Actions  */

/****************************************************/

export default {
  actionKaiSessionId,
  actionDebugMode,
  actionInputMessage,
  actionSendMessage,
  actionSendSelection,
  actionResponseData,
  actionRenderingStatus,
  actionTypingIndicator,
  actionLastMessageReceievedEpoc,
  actionCarouselCounter,
  actionPullServiceStart,
  actionPullServiceStop,
  actionRequestHeaders,
  actionUserTyping,
  actionAvatarUpdate,
  actionAvatarPersistentUpdate,
  actionAvatarTitle,
  actionShortcutPayload,
  actionShortcutIcon,
  actionShowInlinePopup,
  actionInlinePopupContent,
  actionBotLanguages,
  actionUseAvatar,
  actionUseHeaderBar,
  actionSlotsDefault,
  actionSpliceSelectedItemsIndex,
  actionPushSelectedItemsIndex,
  actionEmptySelectedItemsIndex
}
