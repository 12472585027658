<template>
<section v-if="$store.state.showShortCutMenu && this.shortcutMenuContent" class="shortcut-container">
  <div class="shortcut-wrapper" navDir="horizontal" ref="keyboardWrapper">
    <div class="shortcut-handle" :tabindex="!isLiveChatStarted? '0': '-1'" navLvl="1" navDir="horizontal" v-on:click="onShortcutPanelHandleClicked()" :class="{anim:handleAnimating, shortcut_handle_open:shortcutPanelOpen}">
      <img v-if="shortcutPanelOpen" :src="this.rightArrowIcon" alt="" :style="this.extraStyle">
      <img v-else :src="this.leftArrowIcon" alt="" :style="this.extraStyle">
    </div>
    <div class="shortcut-content" :class="{shortcut_content_open:displayShortcutContent}" role="region" :aria-roledescription="screenReaderTranslations.shortcutMenu">
      <h1>
        <span><svg class="svg-heart" width="18px" height="16px" viewBox="0 0 18 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" v-html="this.heartLogoSvg"></svg> {{shortcutMenuTitle}}</span>
      </h1>
      <hr />
      <div class="" v-for="(shortcutItem, itemIndex) in this.shortcutMenuContent" :key="itemIndex">
        <template v-if="shortcutItem != undefined">
          <div class="shortcut-header">{{shortcutItem.payload.title}}
            <span role="button" :aria-label="screenReaderTranslations.shortcutInfoButton" class="shortcut-info-btn mouse-pointer" v-on:click="shortcutInfoShow(shortcutItem.payload.title, shortcutItem.payload.subtitle)"><img :src="infoIcon" alt=""></span>
          </div>
          <div class="shortcut-item-wrapper">
            <div v-for="(shortcutList, itemIndex) in  shortcutItem.payload.buttons" :key="itemIndex">
              <div class="shortcut-item mouse-pointer" role="button" :aria-label="shortcutList.label" :tabindex="shortcutPanelOpen? '0': '-1'" navLvl="2" navDir="both" @click="sendShortcutItem(shortcutList)"> {{shortcutList.label}}</div>
            </div>
          </div>
          <div class="clear-it"></div>
        </template>
      </div>
    </div>

  </div>
</section>
</template>

<script>
import './styles/ShortcutPanel.less'
import KeyboardNavigationMixin from './Mixin/KeyboardNavigationMixin'
import ImageUtilityMixin from './Mixin/ImageUtilityMixin'
import Cookies from 'js-cookie'
import popupShortcutInfoJsonData from '../../debug/popup_shortcut_info.json'

export default {
  name: 'ShortcutPanel',
  props: [],
  data () {
    return {
      shortcutPanelOpen: false,
      displayShortcutContent: false,
      rightArrowIcon: this.getImagePath('Hamburger.png'),
      leftArrowIcon: this.getImagePath('Hamburger.png'),
      heartIcon: this.getImagePath('svg/heart.svg'),
      infoIcon: this.getImagePath('svg/info_icon.svg'),
      extraStyle: 'width: 14.4px; top: calc(50% - 5.5px); left: 3px;',
      handleAnimating: false,
      animationTimerDone: false,
      animationTimerHolder: false,
      screenReaderTranslations: $store.getters.getBotLanguages.translations.screenReader,
      heartLogoSvg: this.getHeartInnerSVGString()
    }
  },
  mounted () {},
  computed: { // Listen to see if store state change
    shortcutMenuContent () {
      let content = this.$store.getters.getShortcutPayloadContent
      if (!content) {
        if (this.shortcutPanelOpen) {
          this.closeShortcutPanel()
        }
        return content
      }
      else return content.sort(this.compareShortcutsPriority)
    },
    updateShortcutIcon () {
      return this.$store.getters.getShortcutIcon
    },
    isLiveChatStarted () {
      return this.$store.getters.isLiveChatStarted
    },
    shortcutMenuTitle () {
      return $store.getters.getBotLanguages.translations.shortcutMenu.title
    }
  },
  watch: {
    shortcutMenuContent () {
      this.triggerAnimationTimer(5000)
    },
    updateShortcutIcon () {
      var icon = this.$store.getters.getShortcutIcon.toUpperCase()
      this.stopArrowAnim()
      if (icon === 'ARROW') {
        this.rightArrowIcon = this.getImagePath('panel-right-arrow.png')
        this.leftArrowIcon = this.getImagePath('panel-left-arrow.png')
        this.extraStyle = ''
      }
      if (icon === 'ARROW_ANIMATE' || icon === 'HANDLE_ANIMATE') {
        this.rightArrowIcon = this.getImagePath('panel-right-arrow.png')
        this.leftArrowIcon = this.getImagePath('panel-left-arrow.png')
        this.extraStyle = ''
        this.animationTimerDone = false
        this.triggerAnimationTimer(5000)
      }
      if (icon === 'HEART') {
        this.rightArrowIcon = this.getImagePath('svg/heart.svg')
        this.leftArrowIcon = this.getImagePath('svg/heart.svg')
        this.extraStyle = 'width: 80%;left: 2px;top: calc(50% - 7.5px);'
      }
      if (icon === 'HEART_HAMBURGER') {
        this.rightArrowIcon = this.getImagePath('Heart.png')
        this.leftArrowIcon = this.getImagePath('Heart.png')
        this.extraStyle = 'width: 82%; left: 2.5px; top: calc(50% - 7.5px);'
      }
      if (icon === 'HEART_MENU') {
        this.rightArrowIcon = this.getImagePath('Heart_Menu.png')
        this.leftArrowIcon = this.getImagePath('Heart_Menu.png')
        this.extraStyle = 'width: 72%; top: calc(50% - 5.5px); left: 3px;'
      }
      if (icon === 'HAMBURGER') {
        this.rightArrowIcon = this.getImagePath('Hamburger.png')
        this.leftArrowIcon = this.getImagePath('Hamburger.png')
        this.extraStyle = 'width: 72%; top: calc(50% - 5.5px); left: 3px;'
      }
      if (icon === 'HAMBURGER_ANIMATE') {
        this.rightArrowIcon = this.getImagePath('Hamburger.png')
        this.leftArrowIcon = this.getImagePath('Hamburger.png')
        this.extraStyle = 'width: 14.4px; top: calc(50% - 5.5px); left: 3px;'
        this.animationTimerDone = false
        this.triggerAnimationTimer(5000)
      }
    },
    isLiveChatStarted () {
      if (document.querySelector('.shortcut-wrapper') !== null) {
        if ($store.getters.isLiveChatStarted) {
          this.hideHandleAnim()
        } else {
          this.showHandleAnim()
          this.triggerAnimationTimer(5000)
        }
      }
    }
  },
  methods: {
    onShortcutPanelHandleClicked () {
      this.toggleShortcutPanel()
      Kai.API.sendUIEvent(`shortcut_menu_${this.shortcutPanelOpen ? 'opened' : 'closed'}`, 'handle_button', 'clicked', `Shortcut Menu ${this.shortcutPanelOpen ? 'Opened' : 'Closed'}`)
    },
    toggleShortcutPanel: function () {
      // let btn = document.querySelector('button');
      this.stopArrowAnim()
      const slideinPanel = document.querySelector('.shortcut-wrapper')
      slideinPanel.classList.toggle('shortcut-open')
      // console.log('slideinPanel.classList', slideinPanel.classList)
      if (slideinPanel.classList.contains('shortcut-open')) {
        this.shortcutPanelOpen = true
        this.displayShortcutContent = true
        this.animationTimerDone = true
        Cookies.set('k_shortcut_panel_opened', true)
      } else {
        this.shortcutPanelOpen = false
        // wait 500ms before setting displayShortcutContent to false,
        // (it's the time for CSS transition defined in the class .shortcut-wrapper in ShortcutPanel.less to end)
        setTimeout(() => { this.displayShortcutContent = false }, 500)
        this.startArrowAnim()
      }
    },
    closeShortcutPanel () {
      this.stopArrowAnim()
      const slideinPanel = document.querySelector('.shortcut-wrapper')
      slideinPanel.classList.remove('shortcut-open')
      this.shortcutPanelOpen = false
      this.startArrowAnim()
    },
    sendShortcutItem: function (shortcutItem) {
      Kai.Core.buttonPressed(shortcutItem)
      this.toggleShortcutPanel()
      Kai.API.sendUIEvent(`shortcut_menu_${this.shortcutPanelOpen ? 'opened' : 'closed'}`, 'shortcut_item_button', 'clicked', `Shortcut Menu ${this.shortcutPanelOpen ? 'Opened' : 'Closed'}`)
    },
    shortcutInfoShow (header, listinfo) {
      let popup = popupShortcutInfoJsonData.message_contents[0].payload.payload
      popup.title = header
      popup.subtitle = listinfo

      Kai.Core.inlinePopup(popup, 'SHORTCUT_INFO')
      Kai.API.sendUIEvent('shortcut_menu_info', 'info_button', 'clicked', popup && popup.title ? `Info displayed for "${popup.title}"` : null)
    },
    startArrowAnim () {
      this.stopArrowAnim()
      if (!this.animationTimerDone) {
        if (this.$store.getters.getShortcutIcon.toUpperCase() === 'ARROW_ANIMATE') {
          const $arrow = document.querySelector('.shortcut-handle img')

          $arrow.animate([{
            left: '5px'
          },
          {
            left: '2.5px'
          },
          {
            left: '5px'
          },
          {
            left: '5px'
          },
          {
            left: '5px'
          },
          {
            left: '5px'
          },
          {
            left: '5px'
          }
          ], {
            duration: 2000,
            iterations: 2
          })
        }
        if (this.$store.getters.getShortcutIcon.toUpperCase() === 'HAMBURGER_ANIMATE' ||
          this.$store.getters.getShortcutIcon.toUpperCase() === 'HANDLE_ANIMATE') {
          this.handleAnimating = true
          const $handle = document.querySelector('.shortcut-handle')

          $handle.animate([{
            left: 'calc(0% - 20px)'
          },
          {
            left: 'calc(0% - 25px)'
          },
          {
            left: 'calc(0% - 20px)'
          },
          {
            left: 'calc(0% - 20px)'
          },
          {
            left: 'calc(0% - 20px)'
          },
          {
            left: 'calc(0% - 20px)'
          },
          {
            left: 'calc(0% - 20px)'
          },
          {
            left: 'calc(0% - 20px)'
          },
          {
            left: 'calc(0% - 20px)'
          },
          {
            left: 'calc(0% - 20px)'
          }
          ], {
            duration: 2000,
            iterations: 2
          })
        }
      }
    },
    stopArrowAnim () {
      this.handleAnimating = false
      var arrow = document.querySelector('.shortcut-handle')
      var animation = arrow.getAnimations()
      if (animation.length > 0) {
        animation[0].cancel()
      }
      arrow = document.querySelector('.shortcut-handle img')
      animation = arrow.getAnimations()
      if (animation.length > 0) {
        animation[0].cancel()
      }
    },
    triggerAnimationTimer (duration) {
      var panelOpened = Cookies.get('k_shortcut_panel_opened')
      if (!panelOpened && !this.animationTimerDone && this.shortcutMenuContent) {
        var _this = this
        if (this.animationTimerHolder) {
          clearTimeout(this.animationTimerHolder)
        }
        this.animationTimerHolder = setTimeout(function () {
          if (!$store.getters.isLiveChatStarted) {
            _this.startArrowAnim()
            _this.triggerAnimationTimer(10000)
          }
        }, duration)
      }
    },
    hideHandleAnim () {
      this.closeShortcutPanel()
      this.stopArrowAnim()
      this.handleAnimating = true
      const $handle = document.querySelector('.shortcut-handle')

      $handle.animate([{
        left: 'calc(0% - 20px)'
      },
      {
        left: 'calc(0%)'
      }
      ], {
        duration: 1000,
        iterations: 1,
        fill: 'forwards'
      })
    },
    showHandleAnim () {
      this.stopArrowAnim()
      this.handleAnimating = true
      const $handle = document.querySelector('.shortcut-handle')

      $handle.animate([
        {
          left: 'calc(0%)'
        },
        {
          left: 'calc(0% - 20px)'
        }
      ], {
        duration: 1000,
        iterations: 1,
        fill: 'forwards'
      })
    },
    compareShortcutsPriority: (a, b) => {
      if (!a.webviewPriority && !b.webviewPriority) { return 0 }
      if (!a.webviewPriority && b.webviewPriority) { return -1 }
      if (a.webviewPriority && !b.webviewPriority) { return 1 }
      if (a.webviewPriority < b.webviewPriority) { return -1 }
      if (a.webviewPriority > b.webviewPriority) { return 1 }
      if (a.webviewPriority === b.webviewPriority) { return 0 }
      return 0
    }
  },
  mixins: [
    KeyboardNavigationMixin,
    ImageUtilityMixin
  ]
}
</script>
