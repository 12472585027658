module.exports = {
  methods: {
    getImagePath (fileName) {
      if ($store.getters.isInlineImagesEnabled) {
        return require('@/assets/img/' + fileName)
      } else {
        return $store.getters.getBaseImagePath + fileName
      }
    },
    getAgentSVGString () {
      // TODO: ideally we shouldn't reference inline SVG string here,
      // we should use "import agentSVGFile from '@/assets/img/Agent.svg'"
      // But when doing so vue.config.js / webpack just reference the SVG path as for an image instead of loading the full SVG inline.
      // And we need inline SVG to use CSS color inheritance from parent containers.
      return '<svg width="300" height="300" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M34.84 35.4666C29.3147 34.2732 25.6311 38.9475 24.48 41.4339C24.48 36.6601 24.48 34.9945 23 34C24.9733 27.2371 30.7289 23.8636 33.36 22.5376C35.9911 21.3773 43.0293 19.7529 50.1333 22.5376C57.2373 25.3223 59.6711 31.3227 60 34.4721C58.816 35.6655 58.3556 38.616 58.52 38.9475V49.3902C51.0213 50.1858 46.3511 44.749 44.7067 41.9311C43.72 40.2736 40.3653 36.6601 34.84 35.4666Z" fill="currentColor"/><path d="M30.8933 76.2428V69.5C38 73.5 46.54 71.9918 49.5 70.5C50.2893 72.4891 52.2711 73.0934 53.0933 73.2591V76.2428C44.016 79.4253 34.5111 77.5688 30.8933 76.2428Z" fill="currentColor"/><path d="M34.84 35.4666C29.3147 34.2732 25.6311 38.9475 24.48 41.4339C24.48 36.6601 24.48 34.9945 23 34C24.9733 27.2371 30.7289 23.8636 33.36 22.5376C35.9911 21.3773 43.0293 19.7529 50.1333 22.5376C57.2373 25.3223 59.6711 31.3227 60 34.4721C58.816 35.6655 58.3556 38.616 58.52 38.9475V49.3902C51.0213 50.1858 46.3511 44.749 44.7067 41.9311C43.72 40.2736 40.3653 36.6601 34.84 35.4666Z" stroke="currentColor" stroke-width="2"/><path d="M30.8933 76.2428V69.5C38 73.5 46.54 71.9918 49.5 70.5C50.2893 72.4891 52.2711 73.0934 53.0933 73.2591V76.2428C44.016 79.4253 34.5111 77.5688 30.8933 76.2428Z" stroke="currentColor" stroke-width="2"/><path d="M17 34V30.5C17 16.4167 28.1929 5 42 5C55.8071 5 67 16.4167 67 30.5V34" stroke="currentColor" stroke-width="3.5"/><rect x="12" y="36" width="10" height="16" rx="2" fill="currentColor"/><rect x="61" y="36" width="10" height="16" rx="2" fill="currentColor"/><path d="M66 53C66 57.8276 64.4 67.5862 58 68" stroke="currentColor" stroke-width="3.5"/><circle cx="54" cy="68" r="3" fill="currentColor"/><path d="M23.5 53C25.3333 60 32.5 71.2 48.5 66C49.5 64 50.5 62.5 54 62C58 59.6 59.6667 55.3333 60 53" stroke="currentColor" stroke-width="3"/></svg>'
    },
    getWaitSVGString () {
      return '<svg width="300" height="300" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="36.5" cy="36.5" r="34.5" stroke="currentColor" stroke-width="4"/><circle cx="36.5" cy="36.5" r="21.5" stroke="currentColor" stroke-width="4"/><path d="M36.5 15V19.5704" stroke="currentColor" stroke-width="4" stroke-linecap="round"/><path d="M36.5 57.5704V53" stroke="currentColor" stroke-width="4" stroke-linecap="round"/><path d="M58.0093 36.3616L53.4999 36.4172" stroke="currentColor" stroke-width="4" stroke-linecap="round"/><path d="M20.0093 36.3616L15.4999 36.4172" stroke="currentColor" stroke-width="4" stroke-linecap="round"/><path d="M36.5 26.0612V36.5L42.3099 42.3099" stroke="currentColor" stroke-width="4" stroke-linecap="round"/></svg>'
    },
    getHeartInnerSVGString () {
      return '<title>heart</title><g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="HD/Shortcut-Menu---Open" transform="translate(-102.000000, -105.000000)" fill="currentColor"><g id="Group-9" transform="translate(101.974147, 100.000000)"><path d="M9.36778947,6.43974737 L8.87936842,6.92816842 L8.39094737,6.43974737 C6.47094737,4.51974737 3.35852632,4.51974737 1.43936842,6.43974737 C-0.479789474,8.35890526 -0.479789474,11.4713263 1.43936842,13.3904842 L1.92778947,13.8797474 L8.87936842,20.8313263 L16.3193684,13.3904842 C18.2385263,11.4713263 18.2385263,8.35890526 16.3193684,6.43974737 C14.4002105,4.51974737 11.2877895,4.51974737 9.36778947,6.43974737 Z" id="heart"></path></g></g></g>'
    }
  }
}
