<template>
<div class="kai-user-wrapper kai-row clear">
    <div class="kai-chat-wrapper">
        <!-- Chat Message Icon -->
        <MessageProfileImage v-if="this.useStaticProfileImage"></MessageProfileImage>
        <div class="kai-typing-indicator-wrapper" :class="{ 'img-space' : this.useStaticProfileImage !== false}">
           <div class="left-border" ></div>
            <div class="kai-typing-indicator">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import MessageProfileImage from './MessageProfileImage'

import './styles/TypingIndicator.less'

export default {
  name: 'TypingIndicator',
  mounted: function () {
  },
  computed: {
    useStaticProfileImage: function () {
      return !$store.state.useAvatar || $store.getters.isLiveAgentConnected
    }
  },
  watch: {

  },
  methods: {

  },
  components: {
    MessageProfileImage
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
