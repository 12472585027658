/****************************************************/

/* Store Mutations  */

/****************************************************/

const mutateKaiSessionId = (state, payload) => {
  state.kaiSessionId = payload
  // console.log('update user session_id in store with:', state.kaiSessionId)
}

const mutateDebugMode = (state, payload) => {
  state.debug = payload
}

const mutateInputMessage = (state, payload) => {
  state.inputMessage = payload
}

const mutateLastMessageReceievedEpoc = (state, payload) => {
  state.lastMessageReceievedEpoc = payload
}

const mutateCarouselCounter = (state, payload) => {
  state.carouselCounter++
}

const mutateSendMessage = (state, payload) => {
  if (payload === false) {
    state.sendMessage = false
  } else {
    state.sendMessage = true
  }
}

const mutateSendSelection = (state, payload) => {
  if (payload === false) {
    state.sendSelection = false
  } else {
    state.sendSelection = true
  }
}

const mutateResponseData = (state, payload) => {
  state.responseData = payload
}

const mutateRenderingStatus = (state, payload) => {
  state.renderingStatus = payload
}

const mutateTypingIndicator = (state, payload) => {
  state.typingIndicator = payload
}

const mutatePullServiceStatus = (state, payload) => {
  if (payload) {
    state.pullServiceStarted = true
  } else {
    state.pullServiceStarted = false
  }
}

const mutateRequestHeaders = (state, payload) => {
  for (const [key, value] of Object.entries(payload)) {
    // console.log(key, value)
    state.requestHeaders[key] = value
  }
}

const mutateUserTyping = (state, payload) => {
  state.userTyping = payload
}

const mutateAvatar = (state, payload) => {
  state.avatar = payload
}

const mutateAvatarPersistent = (state, payload) => {
  state.avatarPersistent = payload
}

const mutateAvatarTitle = (state, payload) => {
  state.avatarTitle = payload
}

const mutateShortcutPayload = (state, payload) => {
  const priority = (payload.payload && payload.payload.priority) ? parseInt(payload.payload.priority) : NaN
  if (!payload) {
    // if mutation is deliberately triggered with a false payload
    // set the shortcutPayload to false to clear the shortcut menu items.
    state.shortcutPayload = false
  } else if (payload.message_contents && payload.message_contents.length > 0) { // if payload is not empty
    if (!state.shortcutPayload) {
      // if shortcuts menu is empty and there is payload
      const messageContent = payload.message_contents.slice()
      payload.message_contents = []

      let startPosition = isNaN(priority) ? 0 : priority
      // assume that the shortcuts are received ordered
      messageContent.forEach(item => {
        item.webviewPriority = priority
        payload.message_contents.splice(startPosition, 1, item)
        startPosition++
      })
      state.shortcutPayload = payload
    } else {
      // if there is payload with length 1, and priority, use the priority
      if (!isNaN(priority) && payload.message_contents.length === 1) {
        const position = state.shortcutPayload.message_contents.map(x => x.payload.title).indexOf(payload.message_contents[0].payload.title)
        payload.message_contents[0].webviewPriority = priority
        if (position === -1) {
          // if the item is new add it to the correct position in the array
          let x = 0
          while (state.shortcutPayload.message_contents[x] !== undefined && state.shortcutPayload.message_contents[x].webviewPriority < priority) {
            x++
          }
          state.shortcutPayload.message_contents.splice(x, 0, payload.message_contents[0])
        } else {
          // otherwise replace it in the array
          state.shortcutPayload.message_contents.splice(position, 1, payload.message_contents[0])
        }
      } else {
        // if there is no priority OR the length is greater that 1
        const noSort = []

        for (let i = 0; i < payload.message_contents.length; i++) { // loop through payload
          // if the priority is empty, check for the item by title
          const index = state.shortcutPayload.message_contents.map(x => x.payload.title).indexOf(payload.message_contents[i].payload.title)
          if (isNaN(priority)) {
            // if found, check its webviewPriority value
            // replace it, otherwise move it to the end by making use of the noSort array - items without any sort order
            if (index >= 0) {
              payload.message_contents[i].webviewPriority = state.shortcutPayload.message_contents[index].webviewPriority
              state.shortcutPayload.message_contents.splice(index, 1, payload.message_contents[i])
            } else {
              noSort.push(payload.message_contents[i])
            }
          } else {
            payload.message_contents[i].webviewPriority = priority
            state.shortcutPayload.message_contents.splice(index, 1, payload.message_contents[i])
          }
        }
        noSort.forEach(noSortItem => {
          const index = state.shortcutPayload.message_contents.map(x => x.payload.title).indexOf(noSortItem.payload.title)
          if (index === -1) {
            state.shortcutPayload.message_contents.push(noSortItem)
          } else {
            noSortItem.webviewPriority = state.shortcutPayload.message_contents[index].webviewPriority
            state.shortcutPayload.message_contents.splice(index, 1, noSortItem)
          }
        })
      }
    }
  }
}

const mutateShortcutIcon = (state, icon) => {
  state.shortcutIcon = icon
}

const mutateSplashScreen = (state, payload) => {
  state.useSplashScreen = payload
}

const mutateShowInlinePopup = (state, payload) => {
  state.showInlinePopup = payload
}

const mutateInlinePopupContent = (state, payload) => {
  state.inlinePopupContent = payload
}

const mutateBotLanguages = (state, payload) => {
  state.botLanguages = payload
}

const mutateUseAvatar = (state, payload) => {
  state.useAvatar = payload
}

const mutateUseHeaderBar = (state, payload) => {
  state.useHeaderBar = payload
}

const mutateSlotsDefault = (state, payload) => {
  state.slots.default = payload
}

const mutateSpliceSelectedItemsIndex = (state, payload) => {
  state.selectedItemsIndex.splice(payload, 1)
}

const mutatePushSelectedItemsIndex = (state, payload) => {
  state.selectedItemsIndex.push(payload)
}

const mutateEmptySelectedItemsIndex = (state) => {
  state.selectedItemsIndex = []
}
/****************************************************/

/* Export Mutations  */

/****************************************************/

export default {
  mutateKaiSessionId,
  mutateDebugMode,
  mutateInputMessage,
  mutateLastMessageReceievedEpoc,
  mutateCarouselCounter,
  mutateSendMessage,
  mutateSendSelection,
  mutateResponseData,
  mutateRenderingStatus,
  mutateTypingIndicator,
  mutatePullServiceStatus,
  mutateRequestHeaders,
  mutateUserTyping,
  mutateAvatar,
  mutateAvatarPersistent,
  mutateAvatarTitle,
  mutateShortcutPayload,
  mutateSplashScreen,
  mutateShortcutIcon,
  mutateShowInlinePopup,
  mutateInlinePopupContent,
  mutateBotLanguages,
  mutateUseAvatar,
  mutateUseHeaderBar,
  mutateSlotsDefault,
  mutateSpliceSelectedItemsIndex,
  mutatePushSelectedItemsIndex,
  mutateEmptySelectedItemsIndex
}
