<template>
  <section v-if="inlinePopupOpen" class="inline-popup-container">
    <div
      class="inline-popup-wrapper"
      ref="keyboardWrapper"
      :class="{ 'inline-popup-open': inlinePopupOpen }"
    >
      <div
        class="inline-popup-content kai-modal-dialog"
        :class="{ inline_popup_content_open: inlinePopupOpen }"
        role="region"
      >
        <div class="kai-modal-content">
          <div
            v-if="popupContent && popupContent.title"
            class="kai-modal-header kai-inline-modal-header"
          >
            <h4 class="kai-modal-title">{{ popupContent.title }}</h4>
            <button type="button" class="close" v-on:click="popupContent.popupType && popupContent.popupType === 'MINIMIZE' ? buttonEvent('MINIMIZE_WEBVIEW'): buttonEvent('CLOSE')">
              &times;
            </button>
          </div>
          <div class="kai-modal-body">
            <div
              id="kai_inline_modal_content_wrapper"
              class="kai-inline-kai-modal-content-wrapper"
            >
              <div
                v-if="popupContent && popupContent.medium"
                id="kai_inline_modal_image_content_wrapper"
                class="kai-inline-modal-image-content-wrapper"
              >
                <MediumSmall :medium="popupContent.medium"></MediumSmall>
              </div>
              <div
                v-if="popupContent && popupContent.subtitle"
                id="kai_inline_modal_content"
                class="kai-inline-kai-modal-content"
                v-html="popupContent.subtitle"
              ></div>
            </div>
          </div>
          <div
            id="kai_inline_modal_footer"
            v-if="popupContent && popupContent.buttons"
            class="kai-modal-footer kai-inline-modal-footer kai-card-button-container"
          >
            <template v-for="(button, index) in popupContent.buttons">
              <button
                v-if="!button.payload || button.payload == ''"
                :key="index"
                :id="`action_button_${index}`"
                v-on:click="buttonEvent(button.type, button.content)"
                type="button"
                :aria-label="button.label"
                class="kai-card-button"
                :class="{
                  'kai-card-outline-button': isInOutlinedList(button.type)
                }"
              >
                {{ button.label }}
              </button>
              <button
                v-else
                class="kai-card-button"
                :key="index"
                :id="`action_button_${index}`"
                @click="itemButton(button.payload && button.payload.type ? button.payload: button)"
                v-html="button.label"
                role="button"
                :aria-label="button.label"
              ></button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Kai from "../../kai";
import MediumSmall from "./MediumSmall";
import "./styles/InlinePopup.less";

export default {
  name: "InlinePopup",
  components: {
    MediumSmall
  },
  data() {
    return {
      outlineButtonTypeList: ["CANCEL", "CLOSE", "CUSTOM_CANCEL"]
    };
  },
  computed: {
    // Listen to see if store state change
    inlinePopupOpen() {
      return $store.getters.getShowInlinePopup;
    },
    popupContent() {
      return $store.getters.getInlinePopupContent;
    }
  },
  methods: {
    buttonEvent(eventName, eventData) {
      switch (eventName.toUpperCase()) {
        case "CANCEL":
        case "CLOSE":
        case "CUSTOM_CANCEL": {
          this.closeModal();
          Kai.API.sendUIEvent(`popup${this.popupContent.popupType? '_' + this.popupContent.popupType.toLowerCase() : ''}`, `${eventName.toLowerCase()}_button`, 'clicked', this.popupContent && this.popupContent.title ? `Popup for "${this.popupContent.title}"` : null)
          break;
        }
        case "INTENT": {
          Kai.Core.submitIntent(eventData);
          this.closeModal();
          break;
        }
        case "END_LIVECHAT": {
          kserver.CoreAPI.sendLiveChatEndSessionEvent();
          this.closeModal();
          break;
        }
        case "MESSAGE":
        case "TEXT": {
          Kai.Core.sendHiddenUserMessage(eventData);
          this.closeModal();
          break;
        }
        case 'MINIMIZE_WEBVIEW': {
          this.closeModal()
          Kai.API.minimizeWebviewWidget()
          break
        }
        default: {
          Kai.API.sendUIEvent(`popup${this.popupContent.popupType? '_' + this.popupContent.popupType.toLowerCase() : ''}`, 'default_close_button', 'clicked', this.popupContent && this.popupContent.title ? `Popup for "${this.popupContent.title}"` : null)
          this.closeModal();
        }
      }
    },
    closeModal() {
      this.$store.dispatch("actionShowInlinePopup", false);
    },
    itemButton (payload) {
      Kai.Core.buttonPressed(payload)
      this.closeModal()
    },
    isInOutlinedList(buttonType) {
      return (
        this.outlineButtonTypeList.filter(x => x == buttonType).length >= 1
      );
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.kai-inline-modal-image-content-wrapper ::v-deep.medium-small-wrapper {
  float: none;
  margin-left: 0;
  margin-top: 0;
  width: fit-content;
}

/* make sure that the image is small */
.kai-inline-modal-image-content-wrapper ::v-deep.medium-small-wrapper img {
  max-width: 120px;
  max-height: 90px;
  width: auto;
  height: auto;
}
</style>
