<template>
<div class="avatar-title-container" :class="{open:displayAvatarTitle}">
    <div class="avatar-title-inner-container"  :class="{marquee_shown:animate}" ref="titleContainer">
      <div class="avatar-title-banner" :class="{marquee:animate}" v-html="avatarTitle" :style="animationDuration" ref="title"></div>
    </div>
</div>
</template>
<script>

import './styles/AvatarTitle.less'

export default {
  name: 'AvatarTitle',
  data () {
    return {
      forceUpdate: false,
      defaultDurationTime: 15
    }
  },
  mounted () {
    // we need to force update when view is just mounted to calculate itemsWidth, maxTranslateX based on the actual size of each quick reply item
    this.forceUpdate = true
    this.$forceUpdate()
  },
  computed: {
    displayAvatarTitle () {
      return this.$store.getters.getAvatarTitle && this.$store.getters.getAvatarTitle.length > 0
    },
    avatarTitle () {
      this.forceUpdate = true
      this.$forceUpdate()
      return this.$store.getters.getAvatarTitle
    },
    animate () {
      // eslint-disable-next-line no-unused-vars
      const dummy = !this.forceUpdate
      this.forceUpdate = false
      if (this.$refs.titleContainer && this.$refs.title) {
        return this.$refs.titleContainer.clientWidth < this.$refs.title.clientWidth
      } else {
        return false
      }
    },
    dynamicDuration: function () {
      let duration = this.defaultDurationTime
      if (this.$refs.titleContainer && this.$refs.title && this.$refs.titleContainer.clientWidth > this.$refs.title.clientWidth) {
        duration = duration + duration * (this.$refs.title.clientWidth - this.$refs.titleContainer.clientWidth) / this.$refs.title.clientWidth 
      }
      return duration
    },
    animationDuration: function () {
      return { '--avatar-title-duration': this.dynamicDuration.toString() + 's' }
    }
  }
}
</script>
