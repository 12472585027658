<template>
<div class="live-chat-widget" :class="{live_chat_open_up:isLiveChatStarted && openUp, live_chat_open_down: isLiveChatStarted && !openUp}" >
    <div class="live-chat-widget-inner-container">
      <div class="live-chat-button live-chat-end-button" tabindex="0" v-on:click="endLiveChatConfirmation()">{{endChatLabel}}</div>
    </div>
</div>
</template>
<script>

import KeyboardNavigationMixin from './Mixin/KeyboardNavigationMixin'
import './styles/LiveChatWidget.less'
import popupEndLivechatJsonData from '../../debug/popup_end_livechat.json'

export default {
  name: 'LiveChatWidget',
  props: ['openUp'],
  data () {
    return {
      endChatLabel: $store.getters.getBotLanguages.translations.liveChatMessages.endChatLabel
    }
  },
  mounted () {},
  computed: {
    isLiveChatStarted () {
      return this.$store.getters.isLiveChatStarted
    }
  },
  mixins: [
    KeyboardNavigationMixin
  ],
  methods: {
    endLiveChatConfirmation () {
      //get the translated strings
      let translations = this.$store.getters.getBotLanguages.translations.inlinePopup.endLivechatConfirmation
      let popup = JSON.parse(JSON.stringify(popupEndLivechatJsonData));
      popup = (popup.message_contents[0].payload.data) 
          ? popup.message_contents[0].payload.data
          : popup.message_contents[0].payload.payload
      if (translations !== undefined) {
        if (translations.title !== undefined && translations.title !== "") popup.title = translations.title
        if (translations.subtitle !== undefined && translations.subtitle !== "") popup.subtitle = translations.subtitle
        if (translations.buttons !== undefined) {
          popup.buttons.forEach(button => {
            if (button.type.toUpperCase() == 'CLOSE') button.label = translations.buttons.closeButtonLabel
            else if (button.type.toUpperCase() == 'END_LIVECHAT') button.label = translations.buttons.endChatButtonLabel
          })
        }
      }
      Kai.Core.inlinePopup(popup, 'LIVECHAT_END_SESSION_CONFIRMATION')
    }
  }
}
</script>
