<template>
   <div v-if="this.agentSVG" class="kai-message-icon">
    <div class="svg-wrapper" v-html="this.agentSVG">
    </div>
   </div>
   <div v-else class="kai-message-icon">
    <img ref="icon">
  </div>
</template>

<script>
import ImageUtilityMixin from './Mixin/ImageUtilityMixin'

export default {
  name: 'MessageProfileImage',
  mixins: [
    ImageUtilityMixin
  ],
  mounted: function () {
    const icon = this.$refs.icon
    if (!window.$store.getters.isInlineImagesEnabled) {
      this.imageLoadErrorHolder = () => this.onImageLoadError(this, icon)
      icon.addEventListener('error', this.imageLoadErrorHolder)
    }
    if (window.$store.getters.isLiveAgentConnected) {
      this.agentSVG = this.getAgentSVGString()
    } else {
      if (!window.$store.getters.isInlineImagesEnabled) {
        this.$options.iconPath = window.$store.state.botWidgetIconUrl
      } else {
        try {
          this.$options.iconPath = require('@/assets/img/' + $store.getters.getBotWidgetIconInlineUrl)
        } catch (error) {
          this.$options.iconPath = $store.getters.getBotWidgetIconInlineUrl
        }
      }
      icon.setAttribute('src', this.$options.iconPath)
    }
  },
  data () {
    return {
      store: window.$store.state,
      agentSVG: null,
      imageLoadErrorHolder: false
    }
  },
  methods: {
    onImageLoadError (context, element) {
      // if an issue happened just try one more time to run the animation with backup logo
      element.removeEventListener('error', this.imageLoadErrorHolder)
      this.$nextTick(() => {
        element.setAttribute('src', window.$store.state.botWidgetIconBackupUrl)
      })
    }
  },
  iconPath: null
}
</script>
